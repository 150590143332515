
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, computed } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { useStore } from 'vuex';
import { GET_ABILITIES } from '../../graphql/Queries';
import { EDIT_PERMISSION_GROUP } from '../graphql/Mutations';
import { GET_PERMISSION_GROUP } from '../graphql/Queries';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
export default defineComponent({
    name: 'edit permission group',
    components: {
        Input,
        Select,
        InnerLoader
    },
    setup() {
        const emitter: any = inject('emitter');
        const editPermissionGroup = ref<null | HTMLFormElement>(null);
        const modal: any = ref();
        const search = ref('');
        const locale = ref('en');
        const locales: any = ref([]);
        const tempValues = ref({}) as Record<any, any>;
        const store = useStore();
        const loader = ref(false);
        const selectedAbility = ref([]) as Record<any, any>;
        const response = ref([]) as Record<any, any>;
        const ability = ref({
            id: null,
            name: ''
        });
        const getAbilities = (page = 0) => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_ABILITIES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                response.value = JSON.parse(data.abilities);
                response.value.forEach(element => {
                    if (element.permission_group_id == ability.value.id) {
                        selectedAbility.value.push(String(element.id));
                    }
                });
                loader.value = false;
            });
        };
        const abilities = computed(() => {
            const storeAbility = [] as Record<any, any>;
            response.value.forEach(element => {
                if (element.permission_group_id == null || element.permission_group_id == ability.value?.id) {
                    storeAbility.push({
                        label: element.title,
                        value: element.name,
                        id: element.id
                    });
                }
            });
            if (search.value != '') {
                return storeAbility.filter(item => {
                    return search.value
                        .toLowerCase()
                        .split(' ')
                        .every(v => item.label.toLowerCase().includes(v));
                });
            } else {
                return storeAbility;
            }
        });

        // On change input value
        const changeLang = (e: string) => {
            ability.value.name = tempValues.value[e].name;
        };
        // update on change value input
        const updateValue = (e: string) => {
            tempValues.value[locale.value].name = e;
        };
        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });
                tempValues.value[element.locale] = { name: '' };
            });
        };
        loadLang();
        const resetForm = () => {
            selectedAbility.value = [];
            getAbilities();
            search.value = '';
            emitter.emit('clearInput');
            editPermissionGroup.value?.resetFields();
            locale.value = 'en';
            for (const i of Object.getOwnPropertyNames(tempValues.value)) {
                tempValues.value[i].name = '';
            }
        };
        emitter.on('editPermissionGroupAction', permission_group => {
            resetForm();
            ability.value.id = permission_group.id;
            const json_decode_name = JSON.parse(permission_group.name);
            for (const i in json_decode_name) {
                i == 'en' ? (ability.value.name = json_decode_name[i]?.name) : '';
                tempValues.value[i].name = json_decode_name[i]?.name;
            }
            modal.value = new Modal(document.getElementById('modal_edit_permission_group'));
            modal.value.show();
        });
        const submitHandler = async () => {
            editPermissionGroup.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        id: ability.value.id,
                        name: JSON.stringify(tempValues.value),
                        abilities: selectedAbility.value
                    };
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: EDIT_PERMISSION_GROUP,
                        variables: { input: formData },
                        update: (store, { data: { update_permission_group } }) => {
                            loader.value = false;
                            modal.value.hide();
                            Notify.success('Record Update Successfully');
                        }
                    }).catch(e => {
                        console.log(e);
                        loader.value = false;
                    });
                }
            });
        };
        return {
            search,
            abilities,
            ability,
            selectedAbility,
            editPermissionGroup,
            loader,
            submitHandler,
            locale,
            locales,
            changeLang,
            updateValue
        };
    }
});
