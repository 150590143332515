
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, computed } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { useStore } from 'vuex';
import { GET_ABILITIES } from '../../graphql/Queries';
import { CREATE_PERMISSION_GROUP } from '../graphql/Mutations';
import { GET_PERMISSION_GROUP } from '../graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
export default defineComponent({
    name: 'permission Group',
    components: {
        Input,
        Select,
        InnerLoader
    },
    setup() {
        const emitter: any = inject('emitter');
        const addPermissionGroup = ref<null | HTMLFormElement>(null);
        const modal: any = ref();
        const search = ref('');
        const locale = ref('en');
        const locales: any = ref([]);
        const tempValues = ref({}) as Record<any, any>;
        const store = useStore();
        const i18n = useI18n();
        const loader = ref(false);
        const selectedAbility = ref([]);
        const response = ref([]) as Record<any, any>;
        const ability = ref({
            name: ''
        });

        const getAbilities = (page = 0) => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_ABILITIES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                response.value = JSON.parse(data.abilities);
                loader.value = false;
            });
        };

        const abilities = computed(() => {
            const ability = [] as Record<any, any>;
            response.value.forEach(element => {
                if (element.permission_group_id == null) {
                    ability.push({
                        label: element.title,
                        value: element.name,
                        id: element.id
                    });
                }
            });

            if (search.value != '') {
                return ability.filter(item => {
                    return search.value
                        .toLowerCase()
                        .split(' ')
                        .every(v => item.label.toLowerCase().includes(v));
                });
            } else {
                return ability;
            }
        });

        const resetForm = () => {
            getAbilities();
            search.value = '';
            selectedAbility.value = [];
            emitter.emit('clearInput');
            locale.value = 'en';
            addPermissionGroup.value?.resetFields();
            for (const i of Object.getOwnPropertyNames(tempValues.value)) {
                tempValues.value[i].name = '';
            }
        };

        emitter.on('addPermissionGroupAction', () => {
            resetForm();
            modal.value = new Modal(document.getElementById('modal_add_permission_group')) as HTMLElement;
            modal.value.show();
        });

        const submitHandler = async () => {
            addPermissionGroup.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        name: JSON.stringify(tempValues.value),
                        abilities: selectedAbility.value
                    };
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: CREATE_PERMISSION_GROUP,
                        variables: { input: formData },
                        update: (store, { data: { create_permission_group } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_PERMISSION_GROUP,
                                variables: {
                                    page: 1,
                                    limit: 10
                                }
                            }) as Record<any, any>;

                            store.writeQuery({
                                query: GET_PERMISSION_GROUP,
                                variables: {
                                    page: 1,
                                    limit: 10
                                },
                                data: {
                                    permission_groups: {
                                        ...pervious_record.permission_groups,
                                        data: [create_permission_group, ...pervious_record.permission_groups.data]
                                    }
                                }
                            });

                            loader.value = false;
                            modal.value.hide();
                            Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        }
                    }).catch(e => {
                        console.log(e);
                        loader.value = false;
                    });
                }
            });
        };

        // On change input value
        const changeLang = (e: string) => {
            ability.value.name = tempValues.value[e].name;
        };

        // update on change value input
        const updateValue = (e: string) => {
            tempValues.value[locale.value].name = e;
        };

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                tempValues.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        return {
            search,
            abilities,
            ability,
            selectedAbility,
            addPermissionGroup,
            loader,
            submitHandler,
            locale,
            locales,
            changeLang,
            updateValue
        };
    }
});
